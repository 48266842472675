var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "template-save-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dataset.show,
            "before-close": _vm.closeHandler,
            width: "600px",
            title: "保存模板",
            "destroy-on-close": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dataset, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "saveForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.saveForm,
                rules: _vm.saveFormRule,
                "label-position": "top",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "模板", prop: "templateName" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "20", "show-word-limit": true },
                    model: {
                      value: _vm.saveForm.templateName,
                      callback: function ($$v) {
                        _vm.$set(_vm.saveForm, "templateName", $$v)
                      },
                      expression: "saveForm.templateName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "权限", prop: "permissionType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "permission-input",
                      attrs: { placeholder: "请选择", clearable: "" },
                      on: { change: _vm.permissionTypeChange },
                      model: {
                        value: _vm.saveForm.permissionType,
                        callback: function ($$v) {
                          _vm.$set(_vm.saveForm, "permissionType", $$v)
                        },
                        expression: "saveForm.permissionType",
                      },
                    },
                    _vm._l(_vm.permissionSelectOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                  _c("el-cascader", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.saveForm.permissionType === "point",
                        expression: "saveForm.permissionType === 'point'",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      clearable: "",
                      placeholder: _vm.$t("commons.selectPlease"),
                      options: _vm.permissionOptions,
                      props: { multiple: true },
                    },
                    model: {
                      value: _vm.saveForm.permissionList,
                      callback: function ($$v) {
                        _vm.$set(_vm.saveForm, "permissionList", $$v)
                      },
                      expression: "saveForm.permissionList",
                    },
                  }),
                  _c(
                    "el-select",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.saveForm.permissionType === "role",
                          expression: "saveForm.permissionType === 'role'",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { multiple: "", placeholder: "请选择" },
                      model: {
                        value: _vm.saveForm.permissionList,
                        callback: function ($$v) {
                          _vm.$set(_vm.saveForm, "permissionList", $$v)
                        },
                        expression: "saveForm.permissionList",
                      },
                    },
                    _vm._l(_vm.roleOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分组", prop: "templateType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.saveForm.templateType,
                        callback: function ($$v) {
                          _vm.$set(_vm.saveForm, "templateType", $$v)
                        },
                        expression: "saveForm.templateType",
                      },
                    },
                    _vm._l(_vm.groupOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "设为默认", prop: "isDefault" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.saveForm.isDefault,
                        callback: function ($$v) {
                          _vm.$set(_vm.saveForm, "isDefault", $$v)
                        },
                        expression: "saveForm.isDefault",
                      },
                    },
                    _vm._l(_vm.defaultOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "button-line" },
            [
              _c(
                "r-button",
                { attrs: { plain: "" }, on: { click: _vm.submitHandler } },
                [_vm._v("保存")]
              ),
              _c(
                "r-button",
                {
                  staticClass: "cancel-button",
                  attrs: { type: "cancel" },
                  on: { click: _vm.closeHandler },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }