var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "template-edit-page" },
    [
      _c("div", { staticClass: "left-part" }, [
        _c(
          "div",
          { staticClass: "filter-line" },
          [
            _c("el-input", {
              attrs: { placeholder: "请输入", "suffix-icon": "el-icon-search" },
              on: { input: _vm.searchHandler },
              model: {
                value: _vm.searchKey,
                callback: function ($$v) {
                  _vm.searchKey = $$v
                },
                expression: "searchKey",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.customLoading,
                expression: "customLoading",
              },
            ],
            staticClass: "list-container",
          },
          _vm._l(_vm.customList, function (item) {
            return _c("div", { key: item.groupId }, [
              item.cardList.filter((item) => item.show).length > 0
                ? _c("div", { staticClass: "label-line" }, [
                    _vm._v(
                      "\n          " + _vm._s(item.groupName) + "\n        "
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "main-container" },
                [
                  _c(
                    "VueDraggable",
                    {
                      staticClass: "container",
                      attrs: {
                        animation: "150",
                        ghostClass: "ghost",
                        sort: false,
                        group: { name: "person", pull: "clone", put: false },
                        filter: ".ignore",
                        clone: _vm.cloneHandler,
                      },
                      model: {
                        value: item.cardList,
                        callback: function ($$v) {
                          _vm.$set(item, "cardList", $$v)
                        },
                        expression: "item.cardList",
                      },
                    },
                    _vm._l(item.cardList, function (single) {
                      return _c(
                        "div",
                        {
                          key: single.cardId,
                          staticClass: "item",
                          style: {
                            width: _vm.cardWidthReander(single),
                          },
                        },
                        [
                          single.show
                            ? [_c("CustomCard", { attrs: { dataset: single } })]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "right-part" }, [
        _c("div", { staticClass: "right-header-line" }, [
          _c("div", { staticClass: "title-label" }, [
            _vm._v(_vm._s(_vm.editPageTitle)),
          ]),
          _c(
            "div",
            { staticClass: "buttons-container" },
            [
              _c(
                "r-button",
                { attrs: { type: "danger" }, on: { click: _vm.backHandler } },
                [_vm._v("放弃更改")]
              ),
              _c(
                "r-button",
                { attrs: { plain: "" }, on: { click: _vm.saveHandler } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.detailLoading,
                expression: "detailLoading",
              },
            ],
            staticClass: "drag-container",
          },
          [
            _vm.list.length === 0
              ? _c("div", { staticClass: "no-card" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/customer_add_icon.png"),
                      alt: "",
                    },
                  }),
                  _vm._v("\n        选择组件长按鼠标左键拖动到此区域\n      "),
                ])
              : _vm._e(),
            _c(
              "VueDraggable",
              {
                staticClass: "container",
                attrs: {
                  animation: "150",
                  ghostClass: "ghost",
                  group: "person",
                  filter: ".ignore",
                },
                model: {
                  value: _vm.list,
                  callback: function ($$v) {
                    _vm.list = $$v
                  },
                  expression: "list",
                },
              },
              _vm._l(_vm.list, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "item",
                    style: {
                      width: _vm.cardWidthReander(item),
                    },
                  },
                  [
                    _c("SingleCard", {
                      attrs: { dataset: item, type: "edit" },
                      on: { delete: _vm.deleteHandler },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
      ]),
      _vm.saveDialog.show
        ? _c("TemplateSaveDialog", {
            attrs: { dataset: _vm.saveDialog },
            on: {
              close: () => {
                _vm.saveDialog.show = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }