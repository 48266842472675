<template>
  <div class="template-edit-page">
    <div class="left-part">
      <div class="filter-line">
        <el-input
          placeholder="请输入"
          suffix-icon="el-icon-search"
          @input="searchHandler"
          v-model="searchKey"
        >
        </el-input>
      </div>
      <div class="list-container" v-loading="customLoading">
        <div v-for="item in customList" :key="item.groupId">
          <div
            class="label-line"
            v-if="item.cardList.filter((item) => item.show).length > 0"
          >
            {{ item.groupName }}
          </div>
          <div class="main-container">
            <VueDraggable
              v-model="item.cardList"
              animation="150"
              ghostClass="ghost"
              class="container"
              :sort="false"
              :group="{ name: 'person', pull: 'clone', put: false }"
              filter=".ignore"
              :clone="cloneHandler"
            >
              <div
                v-for="single in item.cardList"
                :key="single.cardId"
                class="item"
                :style="{
                  width: cardWidthReander(single),
                }"
              >
                <template v-if="single.show">
                  <CustomCard :dataset="single" />
                </template>
              </div>
            </VueDraggable>
          </div>
        </div>
      </div>
    </div>
    <div class="right-part">
      <div class="right-header-line">
        <div class="title-label">{{ editPageTitle }}</div>
        <div class="buttons-container">
          <r-button type="danger" @click="backHandler">放弃更改</r-button>
          <r-button plain @click="saveHandler">保存</r-button>
        </div>
      </div>
      <div class="drag-container" v-loading="detailLoading">
        <div class="no-card" v-if="list.length === 0">
          <img src="@/assets/images/customer_add_icon.png" alt="" />
          选择组件长按鼠标左键拖动到此区域
        </div>
        <VueDraggable
          v-model="list"
          animation="150"
          ghostClass="ghost"
          class="container"
          group="person"
          filter=".ignore"
        >
          <div
            v-for="item in list"
            :key="item.id"
            class="item"
            :style="{
              width: cardWidthReander(item),
            }"
          >
            <SingleCard @delete="deleteHandler" :dataset="item" type="edit" />
          </div>
        </VueDraggable>
      </div>
    </div>
    <TemplateSaveDialog
      @close="
        () => {
          saveDialog.show = false;
        }
      "
      :dataset="saveDialog"
      v-if="saveDialog.show"
    />
  </div>
</template>

<script>
import { getTemplateDetailById } from "@/api/ruge/bms/indicatorManagement";
import { getCustomCardList } from "@/api/ruge/bmsNew/dashboard.js";
import { VueDraggable } from "vue-draggable-plus";
import CustomCard from "../../vlink/dashboardEdit/components/customCard.vue";
import SingleCard from "../../vlink/dashboardEdit/components/singleCard.vue";
import TemplateSaveDialog from "./components/templateSaveDialog.vue";
import { GenNonDuplicateID } from "@/utils/utils.js";
import { cloneDeep } from "lodash";

export default {
  name: "template-edit-page",
  components: {
    VueDraggable,
    CustomCard,
    SingleCard,
    TemplateSaveDialog,
  },
  data() {
    return {
      searchKey: null,
      customLoading: false,
      customList: [],
      editPageTitle: "模板编辑",
      detailLoading: false,
      list: [],
      resourceItem: {},
      saveDialog: {
        show: false,
        templateType: null,
        datas: {},
      },
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    deleteHandler(id) {
      this.list = this.list.filter((item) => item.id !== id);
    },
    async backHandler() {
      await this.$confirm(
        `确定放弃当前的更改吗？`,
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: "再想想",
          type: "warning",
        }
      );
      this.$router.push({
        path: "/bms/templateManagement",
        query: {
          templateId: this.resourceItem.templateId,
        },
      });
    },
    saveHandler() {
      const { templateType } = this.$route.query;
      this.saveDialog.templateType = templateType;
      this.saveDialog.datas = {
        ...this.resourceItem,
        ...{ cardList: this.list },
      };
      this.saveDialog.show = true;
    },
    cloneHandler(element) {
      console.log("element", element);
      const cloneObj = cloneDeep(element);
      cloneObj.id = GenNonDuplicateID();
      return cloneObj;
    },
    cardWidthReander(item) {
      const marginMap = {
        1: 20,
        2: 10,
        3: 0,
      };
      const oneStep = 33.3;
      let finalWidth = 33.3;
      const cardSizeParse =
        typeof item.cardSize === "string"
          ? JSON.parse(item.cardSize)
          : item.cardSize;
      finalWidth = cardSizeParse.mode * oneStep;
      return `calc(${finalWidth}% - ${marginMap[cardSizeParse.mode]}px)`;
    },
    searchHandler() {
      const currentKey = this.searchKey.trim().toLowerCase();
      this.customList.forEach((single) => {
        single.cardList.forEach((item) => {
          item.show = item.cardName.toLowerCase().includes(currentKey);
        });
      });
    },
    initDatas() {
      this.getCardList();
      const { templateId, templateName, templateGroup, type } =
        this.$route.query;
      if (!templateId) return;
      this.detailLoading = true;
      getTemplateDetailById({ templateId })
        .then((res) => {
          if (type === "copy") {
            res.templateName += "-复制";
            res.templateId = null;
            res.isDefault = "N";
          }
          this.list = res.cardList.map((item) => {
            item.id = GenNonDuplicateID();
            return item;
          });
          this.resourceItem = res;
        })
        .finally(() => {
          this.detailLoading = false;
          this.editPageTitle = `${templateGroup}-${templateName}`;
          if (type === "copy") {
            this.editPageTitle += "-复制";
          }
        });
    },
    getCardList() {
      // 自定义卡片列表
      this.customLoading = true;
      getCustomCardList()
        .then((res) => {
          res.forEach((item) => {
            item.cardList.forEach((single) => {
              single.show = true;
              single.cardSize = JSON.parse(single.cardSize);
            });
          });
          this.customList = res.filter((item) => item.cardList.length);
        })
        .finally(() => {
          this.customLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.template-edit-page {
  display: flex;
  height: 100vh;
  width: 100%;
  .left-part {
    min-width: 250px;
    background: #f9fbff;
    .filter-line {
      padding: 0 10px;
      margin: 20px 0;
      ::v-deep .el-input__inner {
        background: #f1f6fd;
      }
    }
    .list-container {
      height: calc(100vh - 76px);
      overflow: auto;
      .label-line {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        height: 25px;
        line-height: 20px;
        color: #95a2b9;
        border-bottom: 1px solid #e3e4e5;
        margin: 10px;
      }
      .no-card-container {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        height: 25px;
        line-height: 20px;
        color: #95a2b9;
        border-bottom: 1px solid #e3e4e5;
        margin: 10px;
      }
      .main-container {
        display: flex;
        justify-content: center;
      }
      .template-card {
        margin: 20px auto 0;
        width: 230px;
        height: 267px;
        background: #ffffff;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        border: 1px solid #fafafa;
        padding: 10px;
        box-sizing: border-box;
        .template-name {
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 12px;
          color: #252d3d;
        }
        .template-img {
          width: 209px;
          height: 148px;
          margin: 10px auto 28px;
        }
        .button-container {
          display: flex;
          .single-button {
            cursor: pointer;
            line-height: 36px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            border-radius: 8px;
            flex: 1;
          }
          .view-button {
            border: 1px solid #2a61ff;
            color: #2a61ff;
          }
          .apply-button {
            background: #2a61ff;
            color: #ffffff;
            margin-left: 15px;
          }
        }
      }
    }
  }
  .right-part {
    padding: 0 40px;
    width: 100%;
    .right-header-line {
      height: 72px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-label {
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #152c5b;
      }
    }
    .drag-container {
      height: calc(100vh - 72px);
      overflow: auto;
      padding: 20px 10px 0 0;
      position: relative;
      .container {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        gap: 30px;
        min-height: calc(100% - 20px);
        margin-bottom: 20px;
        width: 100%;
        border: 1px dotted #ccc;
        .item {
          width: 33.3%;
          border-radius: 5px;
          color: #000;
        }
      }
      .no-card {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 14px;
        color: #b5bece;
        img {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>