<template>
  <div class="template-save-dialog">
    <el-dialog
      :visible.sync="dataset.show"
      :before-close="closeHandler"
      width="600px"
      title="保存模板"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-form
        :model="saveForm"
        :rules="saveFormRule"
        label-position="top"
        ref="saveForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="模板" prop="templateName">
          <el-input
            maxlength="20"
            :show-word-limit="true"
            v-model="saveForm.templateName"
          ></el-input>
        </el-form-item>
        <el-form-item label="权限" prop="permissionType">
          <el-select
            class="permission-input"
            v-model="saveForm.permissionType"
            placeholder="请选择"
            clearable
            @change="permissionTypeChange"
          >
            <el-option
              v-for="item in permissionSelectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-cascader
            v-show="saveForm.permissionType === 'point'"
            style="width: 100%"
            v-model="saveForm.permissionList"
            clearable
            :placeholder="$t('commons.selectPlease')"
            :options="permissionOptions"
            :props="{ multiple: true }"
          >
          </el-cascader>
          <el-select
            v-show="saveForm.permissionType === 'role'"
            style="width: 100%"
            multiple
            v-model="saveForm.permissionList"
            placeholder="请选择"
          >
            <el-option
              v-for="item in roleOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分组" prop="templateType">
          <el-select
            style="width: 100%"
            v-model="saveForm.templateType"
            placeholder="请选择"
          >
            <el-option
              v-for="item in groupOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设为默认" prop="isDefault">
          <el-select
            style="width: 100%"
            v-model="saveForm.isDefault"
            placeholder="请选择"
          >
            <el-option
              v-for="item in defaultOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="button-line">
        <r-button plain @click="submitHandler">保存</r-button>
        <r-button type="cancel" class="cancel-button" @click="closeHandler"
          >取消</r-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAllPermission } from "@/api/ruge/security/permission";
import { _tenant_getRolePage } from "@/api/ruge/security/role";
import { permissionStructTree } from "@/utils/tree";
import { getLoopUpItem } from "@/api/business/base/tenant/device";
import { saveTemplate } from "@/api/ruge/bms/indicatorManagement";

export default {
  name: "template-save-dialog",
  props: {
    dataset: {
      tepe: Object,
      default() {
        return {
          show: false,
        };
      },
    },
  },
  data() {
    return {
      saveForm: {
        templateName: null,
        permissionType: null,
        permissionList: [],
        templateType: null,
        isDefault: "N",
        coverPicture: null,
      },
      saveFormRule: {
        templateName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请输入模板名称",
          },
        ],
      },
      permissionSelectOptions: [
        {
          label: "角色",
          value: "role",
        },
        {
          label: "权限点",
          value: "point",
        },
      ],
      permissionOptions: [],
      roleOptions: [],
      groupOptions: [],
      defaultOptions: [
        {
          label: "是",
          value: "Y",
        },
        {
          label: "否",
          value: "N",
        },
      ],
    };
  },
  created() {
    this.initDatas();
  },
  watch: {
    dataset: {
      handler(dateset) {
        if (dateset.datas) {
          this.displayDatas(dateset.datas);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    displayDatas(datas) {
      const {
        templateName,
        permissionType,
        permissionList,
        templateType,
        isDefault,
        templateId,
        coverPicture,
      } = datas;
      this.saveForm.templateName = templateName;
      this.saveForm.coverPicture = coverPicture;
      this.saveForm.templateId = templateId;
      this.saveForm.permissionType = permissionType;
      this.saveForm.permissionList =
        permissionType === "point"
          ? JSON.parse(permissionList).map((item) => {
              return [undefined, undefined, item];
            })
          : JSON.parse(permissionList);
      this.saveForm.templateType = templateType;
      this.saveForm.isDefault = isDefault;
    },
    permissionTypeChange() {
      this.saveForm.permissionList = [];
    },
    async submitHandler() {
      await this.$refs.saveForm.validate();
      this.loading = true;
      const params = {
        ...this.saveForm,
        ...{
          cardIds: this.dataset.datas.cardList.map((item) => item.cardId),
        },
      };
      params.permissionList =
        params.permissionType === "point"
          ? JSON.stringify(
              params.permissionList.map((item) => {
                return item.join("");
              })
            )
          : JSON.stringify(params.permissionList);
      console.log("this.saveForm", params);
      saveTemplate(params)
        .then(({ templateId }) => {
          this.loading = false;
          this.$message.success("保存成功！");
          setTimeout(() => {
            this.$router.push({
              path: "/bms/templateManagement",
              query: {
                templateId,
              },
            });
          }, 1000);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    initDatas() {
      getAllPermission().then((resp) => {
        let cascadeList = null;
        cascadeList = permissionStructTree(resp, this);

        //将第三级的空子节点 children : []设置为 children : undefined
        cascadeList.forEach(function (obj) {
          obj.children.forEach(function (msg) {
            msg.children.forEach(function (msg) {
              msg.children = undefined;
            });
          });
        });
        this.permissionOptions = cascadeList;
      });

      _tenant_getRolePage({
        current: 1,
        rowCount: 100,
      }).then((res) => {
        if (res && res.rows && res.rows.length) {
          this.roleOptions = res.rows.map((item) => {
            return {
              label: item.roleName,
              value: item.roleId,
            };
          });
        }
      });

      getLoopUpItem({
        classifyCode: "BI_TEMPLATE_TYPE",
      })
        .then((res) => {
          this.groupOptions = res.map((item) => {
            return {
              value: item.itemCode,
              label: item.itemName,
            };
          });
        })
        .finally(() => {
          if (this.dataset.templateType) {
            this.saveForm.templateType = this.dataset.templateType;
          }
        });
    },
    closeHandler() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.template-save-dialog {
  .button-line {
    text-align: right;
    margin: 20px 0;
    .cancel-button {
      margin-left: 15px;
    }
  }
  .permission-input {
    width: 100%;
    margin-bottom: 12px;
  }
}
</style>